import axios from 'axios';
import config from '../config';
import { JSEncrypt } from 'jsencrypt';
import { rsaPub } from './contants';

const { API_BASE } = config

const request = axios.create({
  baseURL: API_BASE,
  headers: {
    'Access-Control-Allow-Origin': '*',
  }
});

request.interceptors.response.use(({data: {response}}) => {
  if (response.status === 'ERROR') {
    throw new Error(response.data.message);
  }
  
  return response;
});

export const makeURLToken = () => {
    const crypt = new JSEncrypt();
    crypt.setPublicKey(rsaPub);

    return crypt.encrypt(config.apiKey);
};

export default request;
