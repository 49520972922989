import React, { ReactNode } from 'react';
import { Container } from './styles';

interface PropsType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant: 'one' | 'two' | 'three' | 'four' | 'five' | 'six';
}

export default function Button({ children, ...props }: PropsType) {
  /* eslint-disable react/jsx-props-no-spreading */
  return <Container {...props}>{children}</Container>;
}
