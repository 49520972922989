export const validateLength = (value: string) => {
  return value.length >= 8 && value.length <= 16;
};

export const validateUppercase = (value: string) => {
  const regex = /[A-Z]/;
  return regex.test(value);
};

export const validateLowercase = (value: string) => {
  const regex = /[a-z]/;
  return regex.test(value);
};

export const validateNumber = (value: string) => {
  const regex = /[0-9]/;
  return regex.test(value);
};

export const validateSpecialChar = (value: string) => {
  const regex = /\W|_/;
  return regex.test(value);
};
