import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import alert from '../../assets/svg/alert.svg';

export const UpdateProfileContainer = styled.section`
  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background: ${({ theme }) => theme.colors.white};

  border-radius: 8px;

  padding: 20px;

  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.65);

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: 100vw;
    height: 100vh;

    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const UpdateProfileHeader = styled.header`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UpdateProfileContent = styled.main`
  width: 650px;
  display: flex;
  padding: 0 10px;

  animation: animation-open 0.5s;

  overflow-y: ${({ theme }) => (theme.media.mobile ? 'visible' : 'auto')};

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.blue_90};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.blue_50};
  }

  @keyframes animation-open {
    from {
      width: 0;
      height: 0;
    }
  }

  form {
    width: 100%;
    visibility: hidden;

    animation: open-before 0s 0.5s forwards;
  }

  @keyframes open-before {
    to {
      visibility: visible;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: 100%;
  }
`;

export const UpdateProfileTitle = styled.h2`
  font-weight: bold;
`;

export const ButtonClose = styled.button`
  background: none;
  border: none;

  padding: 5px;

  cursor: pointer;

  transition: 1s filter;

  &:hover {
    filter: opacity(0.5);
  }
`;

export const ErrorPassword = styled.div`
  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }
`;

export const Footer = styled.div`
  padding-top: 1.875rem;
  position: relative;
`;

export const IconLoading = styled(LoadingOutlined)`
  font-size: ${({ theme }) => theme.fonts.size_1};
  color: ${({ theme }) => theme.colors.white};
`;

export const ImgArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ImgProps {
  src: string;
  camera: string;
}

export const Img = styled.label<ImgProps>`
  width: 150px;
  height: 150px;

  position: relative;

  border-radius: 100%;
  margin: 0 auto 20px auto;

  border: 5px solid ${({ theme }) => theme.colors.white};

  cursor: pointer;

  background: ${({ src, camera }) =>
    src ? `url("${src}")` : `url("${camera}") no-repeat center #ccc`};
  background-size: ${({ src }) => (src ? 'cover' : '75px')};

  -webkit-transition: all 1s;
  transition: all 1s;

  &:after {
    content: '';

    width: 0;
    height: 0;

    background: none;

    position: absolute;
    bottom: 0px;
    right: 0px;

    opacity: 0;

    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }

  &:hover {
    border: 5px solid ${({ theme }) => theme.colors.lilac};

    &:after {
      content: '';

      width: 40px;
      height: 40px;
      border-radius: 100%;

      background-color: ${({ theme }) => theme.colors.lilac};
      background-image: ${({ camera }) => `url("${camera}")`};
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: center;

      opacity: 1;

      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
`;

export const AlertRoleChange = styled.div`
  & > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 10px;
  }

  p {
    padding-left: 41px;
    background: url(${alert}) no-repeat 0 5px;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  button {
    margin-bottom: 5px !important;
    font-size: 14px !important;
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 16px;

  &::before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 16px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
  border-radius: 16px;

  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Slider} {
      background-color: #5768ff;
    }

    &:focus + ${Slider} {
      box-shadow: 0 0 1px #5768ff;
    }

    &:checked + ${Slider}:before {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
  }
`;

export const SwitchArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  > p {
    color: var(--escala-de-cinza-80, #252525);

    /* Site/Detalhes 14pt/Det 14pt Regular */
    font-family: 'Work Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    margin-bottom: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 15px 10px;
    border-radius: 5px;
    background: var(--escala-de-cinza-0, #fff);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  }
`;
