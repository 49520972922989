import styled, { css } from "styled-components";
import logo from "../../assets/svg/logo.svg";
import background from "../../assets/background.png";

interface PropsStyle {
    reverse: boolean;
}

export const Container = styled.div<PropsStyle>`
    display: flex;
    flex: 1;
    min-height: 100vh;

    ${({ reverse }) => reverse && css`
        flex-direction: row-reverse;
    `}

    @media(max-width: ${({ theme }) => theme.media.tablet}) {
        display: block;
    }    
`;

export const Header = styled.header<PropsStyle>`
    display: flex;
    flex: 50%;  
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: url(${background}), linear-gradient(173.26deg, ${({ theme }) => theme.colors.pink} -36.58%, ${({ theme }) => theme.colors.primary} 100.9%);
    ${({ reverse }) => reverse && css`
        background: url(${background}), linear-gradient(96.64deg, ${({ theme }) => theme.colors.blue_90} 26.19%, ${({ theme }) => theme.colors.blue_50} 102.4%);
    `}

    background-size: cover;
    backgorund-position: center center;

    @media(max-width: ${({ theme }) => theme.media.tablet}) {
        display: block;
        background: none;
    }
`;

export const Title = styled.h1<PropsStyle>`
    position: absolute;
    top: 3.75rem;
    left: 7.5625rem;
    width: 10.489375rem;
    height: 2.5rem;
    text-indent: -5000em;
    background: url(${logo}) no-repeat;
    background-size: 10.489375rem 2.5rem;
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(88deg) brightness(106%) contrast(101%);

    ${({ reverse }) => reverse && css`
        filter: none;
    `}

    @media(max-width: ${({ theme }) => theme.media.tablet}) {
        position: relative;
        top: auto;
        left: auto;
        margin: 1.875rem auto 2.5rem;
        filter: none;
    }
`;

export const TitleTwo = styled.h2`
    margin-bottom: 1.25rem;
    line-height: 3.75rem;
    font-size: ${({ theme }) => theme.fonts.size_2};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
`;

export const Intro = styled.div`
    width: 29.5625rem;

    @media(max-width: ${({ theme }) => theme.media.tablet}) {
        display: none;
    }
`;

export const Text = styled.p`
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.white};
`;

export const Students = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin: 0;
        color: ${({ theme }) => theme.colors.white};
        font-weight: 500;
        line-height: 1.25rem;
    }
`;

export const Content = styled.div<PropsStyle>`
    display: flex;
    flex: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${({ reverse }) => reverse && css`
        @media(min-width: ${({ theme }) => theme.media.tablet}) {
            justify-content: flex-start;
            padding-top: 9rem;    
        }
    `}
`;