export default {
  colors: {
    transparent: '',
    primary: '#000A66',
    success: '#12C735',
    warning: '#F89C3A',
    default: '#9A9A9A',
    danger: '#e51616',
    white: '#FFFFFF',
    pink: '#D81E5B',
    lilac: '#5768FF',
    lilac_50: '#A1A5CB',
    black_50: '#858585',
    gray_90: '#EFEFEF',
    blue_50: '#000533',
    blue_90: '#2B3589',
    blue_100: '#F0F2FF',
  },
  fonts: {
    size_1: '1rem',
    size_2: '3.4375rem',
    size_3: '2.5rem',
    size_4: '1.25rem',
  },
  media: {
    mobile: '480px',
    tablet: '1000px',
  },
};
