import * as Sentry from '@sentry/react';

import { REHYDRATE } from 'redux-persist/lib/constants';
import _ from 'lodash';
import { createSagaAction } from '../../shared/sagas';
import { createReducer } from '../../shared/reducers';

// Constants
export const constants = {
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_REGISTER: createSagaAction('AUTH_REGISTER'),
  AUTH_LOGIN: createSagaAction('AUTH_LOGIN'),
  AUTH_LOGGED: createSagaAction('AUTH_LOGGED'),
  AUTH_FORGOT_PASSWORD: createSagaAction('AUTH_FORGOT_PASSWORD'),
  AUTH_RESET_PASSWORD: createSagaAction('AUTH_RESET_PASSWORD'),
  AUTH_UPDATE_PHOTO: createSagaAction('AUTH_UPDATE_PHOTO'),
  AUTH_ROLES: createSagaAction('AUTH_ROLES'),
};

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  register: (name, last_name, email, password, phone_number, success, error) => ({
    type: constants.AUTH_REGISTER.ACTION,
    name,
    last_name,
    email,
    password,
    phone_number,
    success,
    error,
  }),
  logout: () => ({
    type: constants.AUTH_LOGOUT,
  }),
  login: (email, password, remember, success, error) => ({
    type: constants.AUTH_LOGIN.ACTION,
    email,
    password,
    remember,
    success,
    error,
  }),
  logged: (token, success, error) => ({
    type: constants.AUTH_LOGGED.ACTION,
    token,
    success,
    error,
  }),
  forgot: (email, success) => ({
    type: constants.AUTH_FORGOT_PASSWORD.ACTION,
    email,
    success,
  }),
  reset: (token, password, password_confirmation, success) => ({
    type: constants.AUTH_RESET_PASSWORD.ACTION,
    token,
    password,
    password_confirmation,
    success,
  }),
  updatePhoto: (foto) => ({
    type: constants.AUTH_UPDATE_PHOTO.ACTION,
    foto,
  }),
  roles: (listRoles, success, error) => ({
    type: constants.AUTH_ROLES.ACTION,
    listRoles,
    success,
    error,
  }),
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // REHYDRATE
  [REHYDRATE]: (state, action) => {
    // const persisted = action.payload ? action.payload.a : {}
    const persisted = _.get(action, 'payload.auth', {});
    if (persisted != null && persisted.user != null) {
      Sentry.setUser({ email: persisted.user.email });
    }
    return {
      ...state,
      avatar: persisted.avatar || initialState.avatar,
      user: persisted.user || initialState.user,
      token: persisted.token || initialState.token,
      listRoles: persisted.listRoles || initialState.listRoles,
    };
  },

  [constants.AUTH_REGISTER.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },

  [constants.AUTH_REGISTER.SUCCESS]: (state, action) => {
    const { token } = action.payload;
    return {
      ...state,
      token,
      isLoading: false,
    };
  },

  [constants.AUTH_REGISTER.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.AUTH_LOGOUT]: () => initialState,

  [constants.AUTH_LOGIN.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },

  [constants.AUTH_LOGIN.SUCCESS]: (state, action) => {
    const { token, email, id, name, photo, profile_id, roles } = action.payload;
    return {
      ...state,
      token,
      user: {
        email,
        id,
        id_profile: profile_id,
        name,
        photo,
        roles,
      },
      avatar: photo,
      isLoading: false,
    };
  },

  [constants.AUTH_LOGIN.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.AUTH_LOGGED.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },

  [constants.AUTH_LOGGED.SUCCESS]: (state, action) => {
    return {
      ...state,
      user: action.payload,
      isLoading: false,
    };
  },

  [constants.AUTH_LOGGED.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.AUTH_FORGOT_PASSWORD.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },

  [constants.AUTH_FORGOT_PASSWORD.SUCCESS]: (state, action) => {
    const { email } = action.payload;
    return { ...state, user: { email }, isLoading: false };
  },

  [constants.AUTH_FORGOT_PASSWORD.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.AUTH_RESET_PASSWORD.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },

  [constants.AUTH_RESET_PASSWORD.SUCCESS]: (state, action) => {
    const { token } = action.payload;
    return { ...state, token, isLoading: false };
  },

  [constants.AUTH_RESET_PASSWORD.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.AUTH_UPDATE_PHOTO.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },

  [constants.AUTH_UPDATE_PHOTO.SUCCESS]: (state, action) => {
    // const user = {...state.user, foto: action.foto}

    return { ...state, avatar: action.foto, isLoading: false };
  },

  [constants.AUTH_UPDATE_PHOTO.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.AUTH_ROLES.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },

  [constants.AUTH_ROLES.SUCCESS]: (state, action) => {
    const listRoles = action.payload;
    return { ...state, listRoles, isLoading: false };
  },

  [constants.AUTH_ROLES.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  isLoading: false,
  token: null,
  avatar: null,
  user: {},
  listRoles: [],
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : { ...state, isLoading: false };
});
