import React, { useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Spin, Checkbox } from 'antd';
import InputMask from 'react-input-mask';
import { actions as authActions } from '../../redux/modules/auth.js';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import { PropsRegisterForm, PropsRegisterPayload, PropsAuth } from '../../shared/types.js';
import {
  validateLength,
  validateLowercase,
  validateNumber,
  validateSpecialChar,
  validateUppercase,
} from '../../shared/util';
import {
  Container,
  Title,
  TextTwo,
  IconLoading,
  ForgotPass,
  Footer,
  ErrorPassword,
} from './styles';

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const stateAuth = useSelector((state: PropsAuth) => state);
  const redirectUrl = searchParams.get('redirectUrl') || undefined;
  const [errorName, setErrorName] = useState<string[]>([]);
  const [errorsPass, setErrorsPass] = useState<string[]>([]);

  const {
    auth: { isLoading },
  } = stateAuth;
  const [form] = Form.useForm();
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const handleFormChange = () => {
    setDisabledSubmit(
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length)
    );
  };

  const validateName = (_: unknown, value: string) => {
    const errors = [];
    const firstName = value.split(' ')[0];
    const lastName = value.split(' ')[1];

    if (!firstName || firstName?.length < 3)
      errors.push('Nome deve conter pelo menos 3 caracteres');

    if (!lastName || lastName?.length < 2)
      errors.push('Sobrenome deve conter pelo menos 2 caracteres');

    if (errors.length > 0) {
      setErrorName(errors);
      return Promise.reject(errors);
    }

    return Promise.resolve();
  };

  const clearPhone = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  const handleSubmit = (values: PropsRegisterForm) => {
    dispatch(
      authActions.register(
        values.name.split(' ')[0],
        values.name.slice(values.name.split(' ')[0].length + 1 || 0) || '',
        values.email,
        values.password,
        clearPhone(values.phone_number),
        (_payload: unknown) => {
          const payload = _payload as PropsRegisterPayload;
          if (payload && payload.token) {
            const rolesToUrl = `roles[]=${payload?.roles}`;
            localStorage.setItem('token', payload.token);
            localStorage.setItem('roles', rolesToUrl);
            if (redirectUrl !== undefined) {
              const joinToken = redirectUrl.includes('?') ? '&' : '/?';
              window.location.replace(
                `${redirectUrl}${joinToken}token=${payload.token}&${rolesToUrl}`
              );
            } else {
              navigate('/roles');
            }
          }
        }
      )
    );
  };

  const navigateTo = () => {
    if (redirectUrl !== undefined) {
      const uri = `/?redirectUrl=${redirectUrl}`;
      return <Link to={uri}>Faça login</Link>;
    }

    return <Link to='/'>Faça login</Link>;
  };

  const validatePassword = (_: unknown, value: string) => {
    const localErrors = [];

    if (!validateLength(value)) {
      localErrors.push('A senha deve conter entre 8 e 16 caracteres.');
    }

    if (!validateUppercase(value)) {
      localErrors.push('A senha deve conter pelo menos uma letra maiúscula.');
    }

    if (!validateLowercase(value)) {
      localErrors.push('A senha deve conter pelo menos uma letra minúscula.');
    }

    if (!validateNumber(value)) {
      localErrors.push('A senha deve conter pelo menos um número.');
    }

    if (!validateSpecialChar(value)) {
      localErrors.push('A senha deve conter pelo menos um caractere especial.');
    }

    if (localErrors.length > 0) {
      setErrorsPass(localErrors);
      return Promise.reject(localErrors);
    }

    return Promise.resolve();
  };

  /* eslint-disable no-template-curly-in-string */
  const validationSchema = {
    required: '${label} é obrigatório!',
    types: {
      email: '${label} não é válido!',
    },
  };

  return (
    <Layout title='Cadastro - IDM' reverse>
      <Container>
        <Title>Crie uma conta ;)</Title>
        <Form
          form={form}
          layout='vertical'
          onFieldsChange={handleFormChange}
          onFinish={handleSubmit}
          validateMessages={validationSchema}
        >
          <Form.Item
            label='Nome'
            name='name'
            validateStatus={errorName.length > 0 ? 'error' : ''}
            help={errorName.map((error) => (
              <ErrorPassword key={error}>{error}</ErrorPassword>
            ))}
            rules={[{ required: true }, { validator: validateName }]}
          >
            <Input placeholder='Seu nome' onChange={() => setErrorName([])} />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
            normalize={(value) => value.toLowerCase()}
          >
            <Input placeholder='Insira seu email' />
          </Form.Item>
          <Form.Item label='Telefone' name='phone_number'>
            <InputMask
              className='ant-input'
              mask='(99) 99999-9999'
              placeholder='Insira seu telefone'
            />
          </Form.Item>
          <Form.Item
            label='Senha'
            name='password'
            rules={[{ required: true }, { validator: validatePassword }]}
            validateStatus={errorsPass.length > 0 ? 'error' : ''}
            help={errorsPass.map((error) => (
              <ErrorPassword key={error}>{error}</ErrorPassword>
            ))}
          >
            <Input.Password placeholder='Crie sua senha' onChange={() => setErrorsPass([])} />
          </Form.Item>
          <Form.Item
            name='allowTerm'
            valuePropName='checked'
            rules={[
              {
                validator: async (_, checked) => {
                  if (!checked) {
                    return Promise.reject(
                      new Error(
                        'Você precisa concordar com os termos e as políticas de privacidade.'
                      )
                    );
                  }

                  return true;
                },
              },
            ]}
          >
            <Checkbox>
              Eu concordo com os{' '}
              <a
                href='https://www.academiarafaeltoro.com.br/termos-de-uso/'
                target='_blank'
                rel='noreferrer'
              >
                Termos de Condições
              </a>{' '}
              e com as{' '}
              <a
                href='https://www.academiarafaeltoro.com.br/politica-de-privacidade/'
                target='_blank'
                rel='noreferrer'
              >
                Políticas de Privacidade
              </a>{' '}
              da Academia Rafael Toro.
            </Checkbox>
          </Form.Item>
          <Footer>
            <Form.Item>
              {isLoading ? (
                <Button variant='one'>
                  <Spin indicator={<IconLoading spin />} />
                </Button>
              ) : (
                <Button variant='one' type='submit' disabled={disabledSubmit}>
                  Criar conta
                </Button>
              )}
              <ForgotPass to='/forget'>Esqueci minha senha</ForgotPass>
            </Form.Item>
          </Footer>
        </Form>
        <TextTwo>Já faz parte da família? {navigateTo()}</TextTwo>
      </Container>
    </Layout>
  );
}
