import request from "../../shared/request";

export function register({ name, last_name, email, password, phone_number }) {
  return request.post(`/api/register`, {
      name,
      last_name,
      email,
      password,
      phone_number
    }).then(({data}) => data).catch((error) => {
      throw new Error(error)
    });
}

export function login({ email, password }) {
  return request.post(`/api/login`, {
      email,
      password,
    }).then(({data}) => data).catch((error) => {
      throw new Error(error)
    });
}

export function logged({ token }) {
  return request.get(`/api/profile`, {
      headers: { 
        Authorization: `Bearer ${token}`,
      }
    }).then(({data}) => data).catch((error) => {
      throw new Error(error);
    });
}

export function forgot({ email }) {
  return request.post(`/api/forgot-password`, {
      email,
    }).then(({data}) => data).catch((error) => {
      throw new Error(error)
    });
}

export function reset({ token, password, password_confirmation }) {
  return request.post(`/api/reset-password`, {
      token,
      password,
      password_confirmation
    }).then(({data}) => data).catch((error) => {
      throw new Error(error)
    });
}

export function update({data, token}){
  return request.put(`/api/profile`, data, {
    headers: { 
      Authorization: `Bearer ${token}`,
    }
  }).then(({data}) => data).catch((error) => {
    throw new Error(error);
  });
}

export function roles() {
  return request.get(`/api/roles`).then(({data}) => data).catch((error) => {
      throw new Error(error);
    });
}
