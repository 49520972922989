import styled, { css } from "styled-components";
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import Button from '../../components/Button';

export const Loading = styled.div`
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
    width: 29.5rem;

    @media(max-width: ${({ theme }) => theme.media.mobile}) {
        width: calc(100% - 1.875rem);
    }

    .ant-form-item-label > label.ant-form-item-required:before {
        content: unset;
    }

    .ant-input {
        border: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius: 0.3125rem;
        height: 2.5rem;
    }

    .ant-input-password {
        border: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius: 0.3125rem;
        padding: 0 0.6875rem;
    }
`;

export const Title = styled.h2`
    margin-bottom: 0.625rem;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fonts.size_3};
    line-height: 2.8125rem;
    text-align: center;

    @media(max-width: ${({ theme }) => theme.media.tablet}) {
        font-size: ${({ theme }) => theme.fonts.size_4};
    }
`;

export const Text = styled.p`
    margin-bottom: 1.875rem;
    line-height: 1.3125rem;
    text-align: center;
`;

export const IconLoading = styled(LoadingOutlined)`
    font-size: ${({ theme }) => theme.fonts.size_1};
    color: ${({ theme }) => theme.colors.white};
`;

export const IconContainer = styled.div`
    margin: 0 auto 1.5625rem;
    width: 4.6875rem;
    height: 4.6875rem;
    background: ${({ theme }) => theme.colors.blue_100};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4.375rem;

    svg {
        color: ${({ theme }) => theme.colors.lilac};
    }
`;

export const Footer = styled.div`
    padding-top: 0.9375rem;
    position: relative;
`;

export const ForgotPass = styled(Link)`
    display: none;
    position: absolute;
    top: -50px;
    right: 0;
    color: ${({ theme }) => theme.colors.lilac};
`;

export const ErrorPassword = styled.div`
    &:first-child {
        margin-top: 10px;
    }

    &:last-child {
        margin-bottom: 10px;
    }
`;