import { all, fork, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { constants } from '../modules/auth'
import * as api from '../api/auth'

import { notification } from 'antd';

import { persistor } from '../store'

function* register(action) {
  try {
    const payload = yield call(api.register, action)
    yield put({ type: constants.AUTH_REGISTER.SUCCESS, payload })
    
    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.AUTH_REGISTER.FAILED,
      message: e.message || e
    })
    notification['error']({
      message: `Problemas no cadastro, tente novamente mais tarde.`
    })

    action.error && action.error();
  }
}

function* watchRegister() {
  yield takeEvery(constants.AUTH_REGISTER.ACTION, register)
}

// LOGIN
function* login(action) {
  try {
    const payload = yield call(api.login, action)
    yield put({ type: constants.AUTH_LOGIN.SUCCESS, payload })
    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.AUTH_LOGIN.FAILED,
      message: e.message || e
    })
    notification['error']({
      message: `Problemas no Login: usuário ou senha inválidos`
    })

    action.error && action.error();
  }
}

function* watchLogin() {
  yield takeEvery(constants.AUTH_LOGIN.ACTION, login)
}

function* forgot(action) {
  try {
    const payload = yield call(api.forgot, action)
    yield put({ type: constants.AUTH_FORGOT_PASSWORD.SUCCESS, payload })
    
    action.success && action.success();
  } catch (e) {
    yield put({
      type: constants.AUTH_FORGOT_PASSWORD.FAILED,
      message: e.message || e
    })
    notification['error']({
      message: `Email não encontrado no sistema!`
    })

    action.error && action.error();
  }
}

function* watchForgot() {
  yield takeEvery(constants.AUTH_FORGOT_PASSWORD.ACTION, forgot)
}

function* reset(action) {
  try {
    const payload = yield call(api.reset, action)
    yield put({ type: constants.AUTH_RESET_PASSWORD.SUCCESS, payload })
    
    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.AUTH_RESET_PASSWORD.FAILED,
      message: e.message || e
    })
    notification['error']({
      message: `Falha ao tentar resetar a senha!`
    })

    action.error && action.error();
  }
}

function* watchReset() {
  yield takeEvery(constants.AUTH_RESET_PASSWORD.ACTION, reset)
}

// LOGGED
function* logged(action) {
  try {
    const payload = yield call(api.logged, action)
    yield put({ type: constants.AUTH_LOGGED.SUCCESS, payload })
    
    action.success && action.success();
  } catch (e) {
    yield put({
      type: constants.AUTH_LOGGED.FAILED,
      message: e.message || e
    })
    
    action.error && action.error();
  }
}

function* watchLogged() {
  yield takeEvery(constants.AUTH_LOGGED.ACTION, logged)
}

function* updatePhoto (action) {
  const {foto} = action
  try {
    yield put({type: constants.AUTH_UPDATE_PHOTO.SUCCESS, foto})
  }
  catch(e) {
    yield put({
      type: constants.AUTH_UPDATE_PHOTO.FAILED,
      message: e.message || e
    })
  }
} 

// ROLES
function* roles(action) {
  try {
    const payload = yield call(api.roles, action)
    yield put({ type: constants.AUTH_ROLES.SUCCESS, payload })
    
    action.success && action.success();
  } catch (e) {
    yield put({
      type: constants.AUTH_ROLES.FAILED,
      message: e.message || e
    })
    
    action.error && action.error();
  }
}

function* watchRoles() {
  yield takeEvery(constants.AUTH_ROLES.ACTION, roles)
}

// LOGOUT
function* logout() {
  yield put({ type: 'LOGOUT' })
  yield call(persistor.purge)
}

function* watchLogout() {
  yield takeLatest(constants.AUTH_LOGOUT, logout)
}

function* watchUpdatePhoto() {
  yield takeEvery(constants.AUTH_UPDATE_PHOTO.ACTION, updatePhoto)
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchRegister), fork(watchReset), fork(watchForgot), fork(watchLogin), fork(watchLogged), fork(watchLogout), fork(watchUpdatePhoto), fork(watchRoles)])
}
