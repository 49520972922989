import styled, { css } from 'styled-components';
import Modal from 'react-modal';

export const Container = styled.section`
  width: 29.5rem;

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: calc(100% - 1.875rem);
  }
`;

export const Title = styled.h2`
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    margin-bottom: 1.25rem;
    text-align: center;
  }
`;

export const Items = styled.div`
  display: flex;
  gap: 1.5625rem;
  flex: 2;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.875rem;

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    display: block;
  }
`;

export const Item = styled.button<{ active?: boolean }>`
  display: flex;
  flex: calc(50% - 0.9375rem);
  width: calc(50% - 0.9375rem);
  height: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.9375em;
  gap: 0.9375em;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    flex: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    height: 3.125rem;
    margin-bottom: 1.25rem;
  }

  ${({ active }) =>
    active
      ? css`
          border: 1px solid ${({ theme }) => theme.colors.lilac};
          color: ${({ theme }) => theme.colors.lilac};
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.black_50};
        `}
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    width: fit-content;
    padding: 0;
  }
`;

export const ModalArea = styled(Modal)`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;
