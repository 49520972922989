import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import rocket from '../../assets/rocket.png';
import { Container, Header, Content, Title, TitleTwo, Intro, Text, Students } from './styles';

interface PropsType {
  children: React.ReactNode;
  title: string;
  reverse: boolean;
}

export default function Layout({
  children,
  title = 'IDM - Academia Rafael Toro',
  reverse = false,
}: PropsType) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container reverse={reverse}>
        <Header reverse={reverse}>
          <Title reverse={reverse}>Rafael Toro Academia de Finanças</Title>
          <Intro>
            {reverse ? (
              <>
                <TitleTwo>
                  Faça parte do #TeamToro! <img src={rocket} alt='' width='50' />
                </TitleTwo>
                <Text>
                  Aprenda com quem vive este universo. Cursos e simulados reais, com metodologia
                  exclusiva de aprendizado em tempo recorde e seguro aprovação de verdade!
                </Text>
                <Students>
                  <p>
                    +29k pessoas já mudaram suas vidas, <br />
                    agora é sua vez!
                  </p>
                </Students>
              </>
            ) : (
              <>
                <TitleTwo>
                  Bem-vindo de volta <img src={rocket} alt='' width='50' />
                </TitleTwo>
                <Text>É tão bom te ter com a gente!</Text>
              </>
            )}
          </Intro>
        </Header>
        <Content reverse={reverse}>{children}</Content>
      </Container>
    </HelmetProvider>
  );
}
