import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Spin } from 'antd';
import { actions as authActions } from '../../redux/modules/auth.js';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import { PropsLoginForm, PropsLoginPayload, PropsAuth } from '../../shared/types.js';
import { Loading, Container, Title, TextTwo, IconLoading, ForgotPass, Footer } from './styles';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') || undefined;
  const stateAuth = useSelector((state: PropsAuth) => state);
  const {
    auth: { isLoading },
  } = stateAuth;
  const [form] = Form.useForm();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const token = localStorage.getItem('token');
  const legacyToken = localStorage.getItem('legacy_token');
  const loginRoles = localStorage.getItem('login_roles');

  const handleNavigate = useCallback(
    (tokenSubmit?: string, legacyTokenSubmit?: string, rolesSubmit?: string) => {
      if (redirectUrl) {
        const tokenSend = token !== null ? token : tokenSubmit;
        const legacyTokenSend = legacyToken !== null ? legacyToken : legacyTokenSubmit;
        const rolesSend = loginRoles !== null ? loginRoles : rolesSubmit;
        const joinToken = redirectUrl.includes('?') ? '&' : '?';
        window.location.replace(
          `${redirectUrl}${joinToken}token=${tokenSend}${
            legacyTokenSend !== 'undefined' ? `&legacy_token=${legacyTokenSend}` : ''
          }${rolesSend !== undefined ? `&${rolesSend}` : ''}`
        );
      } else {
        navigate('/roles');
      }
    },
    [navigate, redirectUrl, token, legacyToken, loginRoles]
  );

  useEffect(() => {
    if (token) {
      dispatch(
        authActions.logged(
          token,
          () => handleNavigate(token),
          () => localStorage.clear()
        )
      );
    }
  }, [dispatch, token, legacyToken, handleNavigate]);

  const handleFormChange = () => {
    setDisabledSubmit(
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length)
    );
  };

  const handleSubmit = (values: PropsLoginForm) => {
    dispatch(
      authActions.login(values.email, values.password, false, (_payload: unknown) => {
        const payload = _payload as PropsLoginPayload;
        if (payload && payload.token) {
          const rolesToUrl = `roles[]=${payload?.roles}`;
          localStorage.setItem('token', payload.token);
          localStorage.setItem('legacy_token', payload.legacy_token);
          localStorage.setItem('login_roles', rolesToUrl);
          handleNavigate(payload.token, payload.legacy_token, rolesToUrl);
        }
      })
    );
  };

  const navigateToRegister = () => {
    if (redirectUrl !== undefined) {
      const uri = `/register/?redirectUrl=${redirectUrl}`;
      return <Link to={uri}>Entre para a família</Link>;
    }

    const uri = `/register`;
    return <Link to={uri}>Entre para a família</Link>;
  };

  const navigateToForgot = () => {
    if (redirectUrl !== undefined) {
      const uri = `/forgot/?redirectUrl=${redirectUrl}`;
      return <ForgotPass to={uri}>Esqueci minha senha</ForgotPass>;
    }

    const uri = `/forgot`;
    return <ForgotPass to={uri}>Esqueci minha senha</ForgotPass>;
  };

  /* eslint-disable no-template-curly-in-string */
  const validationSchema = {
    required: '${label} é obrigatório!',
    types: {
      email: '${label} não é válido!',
    },
  };

  return token ? (
    <Loading>
      <Spin size='large' indicator={<IconLoading spin style={{ color: '#000A66' }} />} />
    </Loading>
  ) : (
    <Layout title='Login - IDM' reverse={false}>
      <Container>
        <Title>Faça Login ;)</Title>
        <Form
          form={form}
          layout='vertical'
          onFieldsChange={handleFormChange}
          onFinish={handleSubmit}
          validateMessages={validationSchema}
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
            normalize={(value) => value.toLowerCase()}
          >
            <Input placeholder='Insira seu email' />
          </Form.Item>
          <Form.Item label='Senha' name='password' rules={[{ required: true }]}>
            <Input.Password placeholder='Insira sua senha' />
          </Form.Item>
          <Footer>
            <Form.Item>
              {isLoading ? (
                <Button variant='one'>
                  <Spin indicator={<IconLoading spin />} />
                </Button>
              ) : (
                <Button variant='one' type='submit' disabled={disabledSubmit}>
                  Continuar
                </Button>
              )}
              {navigateToForgot()}
            </Form.Item>
          </Footer>
        </Form>
        <TextTwo>Não possui conta ainda? {navigateToRegister()}</TextTwo>
      </Container>
    </Layout>
  );
}
