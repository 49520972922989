import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import dayjs from 'dayjs';

import { RangePickerProps } from 'antd/lib/date-picker';

import '../../styles/modal.styles.css';

import { DatePicker, Form, Input, Spin } from 'antd';

import locale from 'antd/lib/date-picker/locale/pt_BR';

import InputMask from 'react-input-mask';

import { X } from 'phosphor-react';

import { toast } from 'react-toastify';
import axios from 'axios';

import Modal from 'react-modal';

import { actions as authActions } from '../../redux/modules/auth.js';
import { update } from '../../redux/api/auth.js';

import Button from '../../components/Button';
import Loading from '../../components/Loading';

import camera from '../../assets/svg/camera.svg';

import {
  ButtonClose,
  ErrorPassword,
  Footer,
  IconLoading,
  Img,
  ImgArea,
  UpdateProfileContainer,
  UpdateProfileContent,
  UpdateProfileHeader,
  UpdateProfileTitle,
  AlertRoleChange,
  Slider,
  Switch,
  SwitchArea,
} from './styles';

import { PropsAuth } from '../../shared/types';

interface UpdateProfileProps {
  closeModal: () => void;
  modalUpdate: boolean;
  roleChange: string | undefined;
  roleChangeAction: string | undefined;
}

interface UserData {
  name: string;
  photo: string;
  birthday_date: string;
  phone_number: string;
  email: string;
}

moment.locale('pt-br');

export default function UpdateProfile({
  closeModal,
  modalUpdate,
  roleChange,
  roleChangeAction,
}: UpdateProfileProps) {
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [modalRoleChange, setModalRoleChange] = useState<boolean>(
    !!roleChange && !!roleChangeAction
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [errorName, setErrorName] = useState<string[]>([]);

  const token = localStorage.getItem('token');

  const notify = () => toast.warning('Mudança de foto indisponível no momento.');

  const stateAuth = useSelector((state: PropsAuth) => state);
  const {
    auth: { isLoading, user },
  } = stateAuth;

  useEffect(() => {
    dispatch(authActions.logged(token));
  }, [modalUpdate, token, dispatch]);

  useEffect(() => {
    if (user) {
      setUserRoles(user.roles.split(','));
    }
  }, [user]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && dayjs().subtract(120, 'year') >= current;
  };

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    const image = event.target.files;
    if (image) {
      formData.append('image', image[0]);
      axios
        .post(`https://staging.api.academiarafaeltoro.com.br/v1/services/s3/student`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => toast.success('Imagem atualizada com sucesso!'))
        .catch(() => toast.error('Falha no processo, tente novamente mais tarde.'));
    }
  };

  const formatPhone = (number: string) => {
    const digits = number.replace(/\D/g, '');

    return `(${digits.slice(0, 2)}) ${digits.slice(2, 7)}-${digits.slice(7)}`;
  };

  const clearPhone = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  const clearUrl = () => {
    if (searchParams.get('roleChange') || searchParams.get('roleChangeAction')) {
      if (searchParams.get('roleChange')) {
        searchParams.delete('roleChange');
      }

      if (searchParams.get('roleChangeAction')) {
        searchParams.delete('roleChangeAction');
      }

      setSearchParams(searchParams);
    }
  };

  const handleSubmit = (values: UserData) => {
    setProcessing(true);

    const dataSend = {
      nome: values.name.split(' ')[0],
      sobre_nome: values.name.slice(values.name.split(' ')[0].length + 1 || 0) || '',
      dt_nascimento: values.birthday_date
        ? moment(values.birthday_date).format('YYYY-MM-DD')
        : undefined,
      telefone: clearPhone(values.phone_number),
      roles: userRoles.join(','),
    };

    update({ data: dataSend, token })
      .then(() => toast.success('Dados atualizados com sucesso!'))
      .catch(() => toast.error('Falha no processo, tente novamente mais tarde.'))
      .finally(() => {
        setProcessing(false);
        clearUrl();
      });
  };

  const validateName = (_: unknown, value: string) => {
    const errors = [];
    const firstName = value.split(' ')[0];
    const lastName = value.split(' ')[1];

    if (!firstName || firstName?.length < 3)
      errors.push('Nome deve conter pelo menos 3 caracteres');

    if (!lastName || lastName?.length < 2)
      errors.push('Sobrenome deve conter pelo menos 2 caracteres');

    if (errors.length > 0) {
      setErrorName(errors);
      return Promise.reject(errors);
    }

    return Promise.resolve();
  };

  /* eslint-disable no-template-curly-in-string */
  const validationSchema = {
    required: '${label} é obrigatório!',
    types: {
      email: '${label} não é válido!',
    },
  };

  const handleConfirmRemoveRole = () => {
    if (userRoles?.indexOf('app-quiz') !== -1) {
      setUserRoles(userRoles.filter((role) => role !== 'app-quiz'));
      setModalRoleChange(false);
    } else {
      setUserRoles([...userRoles, 'app-quiz']);
      setModalRoleChange(false);
    }
  };

  const customStyles = {
    content: {
      width: '365px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '25px 25px 30px',
    },
  };

  const lastName = user.sobre_nome ? user.sobre_nome : '';

  const profileImageUrl =
    user.foto !== null ? `${process.env.REACT_APP_IMAGE_URL}/${user.foto}` : '';

  if (isLoading) return <Loading />;

  return (
    <UpdateProfileContainer>
      <UpdateProfileHeader>
        <UpdateProfileTitle>Atualizar Dados</UpdateProfileTitle>
        <ButtonClose onClick={closeModal}>
          <X size={22} />
        </ButtonClose>
      </UpdateProfileHeader>
      <UpdateProfileContent>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          validateMessages={validationSchema}
        >
          <ImgArea>
            <Img onClick={notify} htmlFor='upload-photo' camera={camera} src={profileImageUrl}>
              <input
                disabled
                onChange={handleChangeImage}
                type='file'
                id='upload-photo'
                hidden
                accept='.jpeg, .jpg, .png'
              />
            </Img>
          </ImgArea>
          <Form.Item
            validateStatus={errorName.length > 0 ? 'error' : ''}
            help={errorName.map((error) => (
              <ErrorPassword key={error}>{error}</ErrorPassword>
            ))}
            label='Nome'
            name='name'
            initialValue={`${user.nome} ${lastName}`}
            rules={[{ required: true }, { validator: validateName }]}
          >
            <Input placeholder='Seu nome' onChange={() => setErrorName([])} />
          </Form.Item>
          <Form.Item
            label='Data Nascimento'
            name='birthday_date'
            initialValue={user.dt_nascimento !== null ? moment(user.dt_nascimento) : undefined}
          >
            <DatePicker
              disabledDate={disabledDate}
              locale={locale}
              style={{ width: '100%' }}
              format='DD/MM/YYYY'
            />
          </Form.Item>
          <Form.Item
            label='Telefone'
            name='phone_number'
            initialValue={user.telefone ? formatPhone(user.telefone) : ''}
          >
            <InputMask
              className='ant-input'
              mask='(99) 99999-9999'
              placeholder='Insira seu telefone'
            />
          </Form.Item>
          <UpdateProfileTitle>Permissões</UpdateProfileTitle>
          <p>
            Configure abaixo as aplicações que podem ter acesso ao seu login da Academia Rafael Toro
          </p>
          <Form.Item name='roles'>
            <SwitchArea>
              <Switch htmlFor='select-item' className='switch'>
                <input
                  id='select-item'
                  type='checkbox'
                  checked={userRoles?.indexOf('app-quiz') !== -1}
                  onChange={() => setModalRoleChange(true)}
                />
                <Slider />
              </Switch>
              <p>App Quiz</p>
            </SwitchArea>
          </Form.Item>
          <Footer>
            <Form.Item>
              {processing ? (
                <Button variant='one'>
                  <Spin indicator={<IconLoading spin />} />
                </Button>
              ) : (
                <Button variant='one' type='submit'>
                  Atualizar
                </Button>
              )}
            </Form.Item>
          </Footer>
          <Modal
            isOpen={modalRoleChange}
            onRequestClose={() => setModalRoleChange(false)}
            contentLabel='Alert Role Change'
            ariaHideApp={false}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
          >
            <AlertRoleChange>
              <div>
                <p>
                  Deseja {userRoles?.indexOf('app-quiz') === -1 ? 'remover' : 'adicionar'} o acesso
                  da aplicação App Quiz ao seu login? Você pode desfazer esta decisão depois :)
                </p>
                <ButtonClose
                  onClick={() => {
                    form.setFieldsValue({ roles: userRoles });
                    setModalRoleChange(false);
                    clearUrl();
                  }}
                >
                  <X size={22} />
                </ButtonClose>
              </div>
              <Button variant='five' onClick={handleConfirmRemoveRole}>
                Sim, desejo {userRoles?.indexOf('app-quiz') === -1 ? 'remover' : 'adicionar'}
              </Button>
              <Button variant='six' onClick={() => setModalRoleChange(false)}>
                Não, cancelar solicitação
              </Button>
            </AlertRoleChange>
          </Modal>
        </Form>
      </UpdateProfileContent>
    </UpdateProfileContainer>
  );
}
