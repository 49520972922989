import styled from "styled-components";
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import Button from '../../components/Button';

export const Container = styled.section`
    width: 29.5rem;

    @media(max-width: ${({ theme }) => theme.media.mobile}) {
        width: calc(100% - 1.875rem);
    }

    .ant-form-item-label > label.ant-form-item-required:before {
        content: unset;
    }

    .ant-input {
        border: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius: 0.3125rem;
        height: 2.5rem;
    }

    .ant-input-password {
        border: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius: 0.3125rem;
        padding: 0 0.6875rem;
    }
`;

export const Title = styled.h2`
    margin-bottom: 1.875rem;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fonts.size_3};
    line-height: 2.8125rem;

    @media(max-width: ${({ theme }) => theme.media.tablet}) {
        font-size: ${({ theme }) => theme.fonts.size_4};
    }
`;

export const ButtonStyled = styled(Button)`
    margin-bottom: 1.875rem;
`;

export const Text = styled.p`
    margin-bottom: 1.875rem;
    text-align: center;

    &::after {
        display: block;
        margin-top: -0.625rem;
        content: '';
        border-bottom: 1px solid ${({ theme }) => theme.colors.lilac_50}; 
    }

    span {
        padding: 0 1.375rem;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.lilac_50};
    }
`;

export const TextTwo = styled.p`
    text-align: center;
    font-weight: 400;

    a {
        font-weight: 500;
        color: ${({ theme }) => theme.colors.pink};
    }
`;

export const IconLoading = styled(LoadingOutlined)`
    font-size: ${({ theme }) => theme.fonts.size_1};
    color: ${({ theme }) => theme.colors.white};
`;

export const Footer = styled.div`
    padding-top: 1.875rem;
    position: relative;
`;

export const ForgotPass = styled(Link)`
    display: none;
    position: absolute;
    top: -50px;
    right: 0;
    color: ${({ theme }) => theme.colors.lilac};
`;

export const ErrorPassword = styled.div`
    &:first-child {
        margin-top: 10px;
    }

    &:last-child {
        margin-bottom: 10px;
    }
`;