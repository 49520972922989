import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Roles from './pages/Roles';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/roles' element={<Roles />} />
      <Route path='/register' element={<Register />} />
      <Route path='/forgot' element={<Forgot />} />
      <Route path='/reset' element={<Reset />} />
    </Routes>
  );
}
