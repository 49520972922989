import { all, fork } from 'redux-saga/effects'

import { rootSaga as auth } from './auth'

function* rootSaga() {
  yield all( [
    fork(auth),
  ])
};

export default rootSaga
