import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Spin } from 'antd';
import { ArrowLeft, Key, EnvelopeSimple } from 'phosphor-react';
import { actions as authActions } from '../../redux/modules/auth.js';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import { PropsForgotForm, PropsAuth } from '../../shared/types.js';
import { Container, Title, Text, TextTwo, IconContainer, IconLoading, Footer } from './styles';

export default function Forgot() {
  const [sent, setSent] = useState<string | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const stateAuth = useSelector((state: PropsAuth) => state);
  const {
    auth: { isLoading },
  } = stateAuth;
  const [form] = Form.useForm();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const redirectUrl = searchParams.get('redirectUrl') || undefined;

  const handleToLogin = () => {
    localStorage.clear();

    if (redirectUrl !== undefined) {
      const uri = `/?redirectUrl=${redirectUrl}`;
      navigate(uri);
    } else {
      navigate('/');
    }
  };

  const handleFormChange = () => {
    setDisabledSubmit(
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length)
    );
  };

  const sendToEmail = (email: string) => {
    dispatch(authActions.forgot(email, () => setSent(email)));
  };

  const handleSubmit = (values: PropsForgotForm) => sendToEmail(values.email);

  const resend = () => sent && sendToEmail(sent);

  const openEmailClient = () => {
    window.location.href = 'mailto:';
  };

  /* eslint-disable no-template-curly-in-string */
  const validationSchema = {
    required: '${label} é obrigatório!',
    types: {
      email: '${label} não é válido!',
    },
  };

  return (
    <Layout title='Esqueceu senha - IDM' reverse={false}>
      <Container>
        {sent ? (
          <>
            <IconContainer sent={!!sent}>
              <EnvelopeSimple size={45} weight='thin' />
            </IconContainer>
            <Title>Verifique seu e-mail</Title>
            <Text>Nós te enviamos um e-mail para {sent}.</Text>
            <Button variant='one' onClick={openEmailClient}>
              Acessar aplicativo de e-mail
            </Button>
            <TextTwo>
              Não recebeu nenhum e-mail?{' '}
              <Button variant='four' onClick={resend}>
                Reenvie o e-mail
              </Button>
            </TextTwo>
          </>
        ) : (
          <>
            <IconContainer sent={!!sent}>
              <Key size={45} weight='thin' />
            </IconContainer>
            <Title>Esqueceu sua senha?</Title>
            <Text>
              Não se preocupe, nós te enviaremos um e-mail <br />
              para que você possa trocá-la :)
            </Text>
            <Form
              form={form}
              layout='vertical'
              onFieldsChange={handleFormChange}
              onFinish={handleSubmit}
              validateMessages={validationSchema}
            >
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
                normalize={(value) => value.toLowerCase()}
              >
                <Input placeholder='Insira seu email' />
              </Form.Item>
              <Footer>
                <Form.Item>
                  {isLoading ? (
                    <Button variant='one'>
                      <Spin indicator={<IconLoading spin />} />
                    </Button>
                  ) : (
                    <Button variant='one' type='submit' disabled={disabledSubmit}>
                      Criar nova senha
                    </Button>
                  )}
                </Form.Item>
              </Footer>
            </Form>
          </>
        )}

        <Button variant='two' onClick={handleToLogin}>
          <ArrowLeft size={18} />
          Voltar para login
        </Button>
      </Container>
    </Layout>
  );
}
