import styled, {css} from "styled-components";

interface PropsButton {
    variant: 'one' | 'two' | 'three' | 'four' | 'five' | 'six';
    disabled?: boolean;
}

export const Container = styled.button<PropsButton>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    width: 100%;
    height: 2.5rem;
    cursor: pointer;
    
    border-radius: 0.3125rem;
    margin-bottom: 0.9375rem;

    ${({ variant }) => {
        switch(variant){
            case 'one': 
                return css`
                    background: ${({ theme }) => theme.colors.pink};
                    color: ${({ theme }) => theme.colors.white};
                `;
            case 'two':
                return css`
                    background: ${({ theme }) => theme.colors.white};
                    color: ${({ theme }) => theme.colors.pink};
                `;
            case 'three':
                return css`
                    border: 1px solid ${({ theme }) => theme.colors.black_50};
                    background: none;
                    color: ${({ theme }) => theme.colors.black_50};
                `;
            case 'four':
                return css`
                    display: inline-flex;
                    padding: 0;
                    width: auto;
                    background: ${({ theme }) => theme.colors.white};
                    color: ${({ theme }) => theme.colors.pink};
                `;
            case 'five': 
                return css`
                    background: ${({ theme }) => theme.colors.lilac};
                    color: ${({ theme }) => theme.colors.white};
                `;
            case 'six': 
                return css`
                    background: ${({ theme }) => theme.colors.white};
                    color: ${({ theme }) => theme.colors.lilac};
                `;
        }
    }}
    
    ${({ disabled }) => disabled && css`
            background: ${({ theme }) => theme.colors.gray_90};
            color: ${({ theme }) => theme.colors.white};
        `
    }
`;