import { LoadingArea, LoadingImage } from './styles';

import LogoLoading from '../../assets/svg/logo-loading.svg';

export default function Loading() {
  return (
    <LoadingArea>
      <LoadingImage url={LogoLoading}>
        <div />
      </LoadingImage>
    </LoadingArea>
  );
}
