import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Spin } from 'antd';
import { ArrowLeft, Keyhole, CheckCircle } from 'phosphor-react';
import { actions as authActions } from '../../redux/modules/auth.js';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import { PropsResetForm, PropsAuth, PropsResetPayload } from '../../shared/types.js';
import {
  validateLength,
  validateLowercase,
  validateNumber,
  validateSpecialChar,
  validateUppercase,
} from '../../shared/util';
import {
  Container,
  Title,
  Text,
  IconContainer,
  IconLoading,
  Footer,
  ErrorPassword,
} from './styles';

export default function Reset() {
  const [reseted, setReseted] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || undefined;
  const stateAuth = useSelector((state: PropsAuth) => state);
  const {
    auth: { isLoading },
  } = stateAuth;
  const [form] = Form.useForm();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [errorsPass, setErrorsPass] = useState<string[]>([]);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  const handleToLogin = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleFormChange = () => {
    setDisabledSubmit(
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length)
    );
  };

  const handleSubmit = (values: PropsResetForm) => {
    dispatch(
      authActions.reset(
        token,
        values.password,
        values.password_confirmation,
        (_payload: unknown) => {
          const payload = _payload as PropsResetPayload;
          if (payload && payload.token) {
            localStorage.setItem('token', payload.token);
            localStorage.setItem('legacy_token', payload.legacy_token);
            setReseted(true);
          }
        }
      )
    );
  };

  const validatePassword = (_: unknown, value: string) => {
    const localErrors = [];

    if (!validateLength(value)) {
      localErrors.push('A senha deve conter entre 8 e 16 caracteres.');
    }

    if (!validateUppercase(value)) {
      localErrors.push('A senha deve conter pelo menos uma letra maiúscula.');
    }

    if (!validateLowercase(value)) {
      localErrors.push('A senha deve conter pelo menos uma letra minúscula.');
    }

    if (!validateNumber(value)) {
      localErrors.push('A senha deve conter pelo menos um número.');
    }

    if (!validateSpecialChar(value)) {
      localErrors.push('A senha deve conter pelo menos um caractere especial.');
    }

    if (localErrors.length > 0) {
      setErrorsPass(localErrors);
      return Promise.reject(localErrors);
    }

    return Promise.resolve();
  };

  /* eslint-disable no-template-curly-in-string */
  const validationSchema = {
    required: '${label} é obrigatório!',
  };

  return (
    <Layout title='Resetar senha - IDM' reverse={false}>
      <Container>
        {reseted ? (
          <>
            <IconContainer>
              <CheckCircle size={55} weight='thin' />
            </IconContainer>
            <Title>Nova senha cadastrada!</Title>
            <Text>
              Sua senha foi modificada com sucesso! Clique no <br />
              botão para entrar na sua conta automaticamente.
            </Text>
            <Button variant='five' onClick={() => navigate('/roles')}>
              Continuar
            </Button>
          </>
        ) : (
          <>
            <IconContainer>
              <Keyhole size={55} weight='thin' />
            </IconContainer>
            <Title>Crie uma nova senha</Title>
            <Text>Sua nova senha deve ser diferente da anteriores.</Text>
            <Form
              form={form}
              layout='vertical'
              onFieldsChange={handleFormChange}
              onFinish={handleSubmit}
              validateMessages={validationSchema}
            >
              <Form.Item
                label='Senha'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira sua senha!',
                  },
                  { validator: validatePassword },
                ]}
                validateStatus={errorsPass.length > 0 ? 'error' : ''}
                help={errorsPass.map((error) => (
                  <ErrorPassword key={error}>{error}</ErrorPassword>
                ))}
              >
                <Input.Password placeholder='Insira sua senha' onChange={() => setErrorsPass([])} />
              </Form.Item>
              <Form.Item
                label='Confirme a senha'
                name='password_confirmation'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Por favor, confirme sua senha!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('As duas senhas que você digitou não correspondem!')
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='Insira sua confirmação da senha' />
              </Form.Item>
              <Footer>
                <Form.Item>
                  {isLoading ? (
                    <Button variant='one'>
                      <Spin indicator={<IconLoading spin />} />
                    </Button>
                  ) : (
                    <Button variant='one' type='submit' disabled={disabledSubmit}>
                      Criar nova senha
                    </Button>
                  )}
                </Form.Item>
              </Footer>
            </Form>
          </>
        )}
        <Button variant='two' onClick={handleToLogin}>
          <ArrowLeft size={18} />
          Voltar para login
        </Button>
      </Container>
    </Layout>
  );
}
